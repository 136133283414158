* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --white: #fff;
  --blue: #e3f2ff;
  --darkblue: #170a5f;
  --mediumblue: #4821d4;
  --lightblue: #ecf5fd;
  --blush: #eeb9c1;
  --dark-grey: #131313;
  --font-grey: #333;
  --medium-grey: #999;
  --red: #ff236c;
  --second-grey: #bbb;
  --border-grey: #e6e6e6;
  --second-white: #f2f2f2;
  --grey0: #fff;
  --grey1: #f2f2f2;
  --grey2: #e6e6e6;
  --grey3: #ccc;
  --grey4: #bbb;
  --grey5: #999;
  --grey6: #4c4c4c;
  --grey7: #333;
  --grey8: #1d1d1d;
  --grey9: #131313;
  --grey10: #000;
  --ice: #8fcef4;
  --ice-low: #e1f2fc;
  --ice-high: #4cb1ee;
  --grape: #696eeb;
  --grape-low: #a8abf3;
  --grape-high: #3037e3;
  --mint: #69eba5;
  --mint-low: #a8f3cb;
  --mint-high: #30e383;
  --lemon: #d5eb69;
  --lemon-low: #e6f3a8;
  --lemon-high: #c5e330;
  --strawberry: #eb696b;
  --strawberry-low: #f3a8a9;
  --strawberry-high: #e33032;
  --error: var(--strawberry-high);
  --font: var(--font-grey);
  --second-font: var(--second-grey);
  --button-font: var(--font-grey);
  --content-font: var(--font-grey);
  --tab-font: var(--second-grey);
  --background: var(--white);
  --secondback: var(--second-white);
  --primary: var(--ice);
  --primary-light: var(--ice-low);
  --secondary: var(--ice-high);
}

.isMobile {
  display: none;
}

@media (max-width: 930px) {
  .isMobile {
    z-index: 100;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(10px);
    background-color: #ffffff05;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0;
  }
}

.mobileText {
  text-align: center;
  font-size: 32px;
}

.mobilePara {
  text-align: center;
  margin-top: 1.25rem;
}

html, body {
  background-color: var(--background);
  font-family: DM Mono;
}

.dark {
  --font: var(--mint);
  --content-font: var(--grey4);
  --second-font: var(--grey5);
  --background: var(--grey8);
  --secondback: var(--grey7);
  --button-font: var(--grey8);
}

body > header {
  height: 5rem;
  background: var(--background);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

body > main {
  width: 100%;
  background: var(--background);
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  padding: 0 10vw;
}

body > footer {
  height: 4rem;
  text-align: center;
  background: var(--background);
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

#logo {
  width: 9rem;
  float: left;
  height: auto;
  margin: 2rem;
}

.icon {
  vertical-align: middle;
  fill: inherit;
  display: inline-flex;
}

.icon:hover {
  fill: inherit;
}

.tab {
  float: left;
  color: var(--content-font);
  fill: var(--second-font);
  margin-top: 2rem;
  margin-left: .5rem;
  margin-right: .5rem;
  padding: .75rem;
  line-height: 20px;
}

.tab:hover {
  cursor: pointer;
  color: var(--content-font);
  fill: var(--font);
  background-color: var(--secondback);
  border-radius: .5rem;
}

.tab:active {
  cursor: pointer;
  background-color: var(--primary-light);
  border-radius: .5rem;
}

.themeChange {
  float: right;
  color: var(--second-font);
  fill: var(--second-font);
  margin-top: 2rem;
  margin-left: .5rem;
  margin-right: .5rem;
  padding: .75rem;
  line-height: 20px;
}

.themeChange:hover {
  cursor: pointer;
  color: var(--font);
  fill: var(--font);
  background-color: var(--secondback);
  border-radius: .5rem;
}

.themeChange:active {
  cursor: pointer;
  background-color: var(--primary-light);
  border-radius: .5rem;
}

.avatar {
  float: right;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  background-color: var(--primary-light);
  border-radius: 10rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  line-height: 40px;
}

.avatar:hover {
  cursor: pointer;
}

#caret {
  width: 4px;
  height: 2.5rem;
  background-color: var(--secondary);
  border-radius: 4px;
  transition: left .1s ease-out;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

#content {
  color: var(--second-font);
  letter-spacing: 1px;
  height: 10.5rem;
  margin-top: 35vh;
  font-size: 2rem;
  overflow: hidden;
}

#startButton {
  background-color: var(--primary-light);
  color: var(--button-font);
  width: 10rem;
  text-align: center;
  border-radius: 8px;
  margin: 2rem auto 0;
  padding: .75rem;
}

#startButton:hover {
  cursor: pointer;
  background-color: #c3eaff;
}

#startButton:active {
  background-color: #a7e0ff;
}

#timeCount {
  text-align: center;
  color: var(--border-grey);
  margin-top: 2rem;
  font-size: 7rem;
}

#helpStuff {
  color: var(--second-font);
  text-align: center;
  margin-top: 1rem;
  font-size: .75rem;
}

.bubble {
  background-color: var(--primary-light);
  color: var(--button-font);
  border-radius: 8px;
  padding: .25rem .5rem;
}

.tabContent {
  color: var(--content-font);
  margin-top: 35vh;
}

.splitting .word, .splitting .char {
  display: inline-block;
}

.splitting .char {
  position: relative;
}

.splitting .char:before, .splitting .char:after {
  content: attr(data-char);
  visibility: hidden;
  user-select: none;
  transition: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.splitting {
  --word-center: calc((var(--word-total)  - 1) / 2);
  --char-center: calc((var(--char-total)  - 1) / 2);
  --line-center: calc((var(--line-total)  - 1) / 2);
}

.splitting .word {
  --word-percent: calc(var(--word-index) / var(--word-total));
  --line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
  --char-percent: calc(var(--char-index) / var(--char-total));
  --char-offset: calc(var(--char-index)  - var(--char-center));
  --distance: calc( (var(--char-offset) * var(--char-offset)) / var(--char-center));
  --distance-sine: calc(var(--char-offset) / var(--char-center));
  --distance-percent: calc((var(--distance) / var(--char-center)) );
}

.splitting.cells img {
  width: 100%;
  display: block;
}

@supports ( display: grid ) {
  .splitting.cells {
    visibility: hidden;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-grid {
    background: inherit;
    width: 100%;
    height: 100%;
    grid-template: repeat( var(--row-total), 1fr ) / repeat( var(--col-total), 1fr );
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
  }

  .splitting .cell {
    background: inherit;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-inner {
    background: inherit;
    visibility: visible;
    width: calc(100% * var(--col-total));
    height: calc(100% * var(--row-total));
    left: calc(-100% * var(--col-index));
    top: calc(-100% * var(--row-index));
    position: absolute;
  }

  .splitting .cell {
    --center-x: calc((var(--col-total)  - 1) / 2);
    --center-y: calc((var(--row-total)  - 1) / 2);
    --offset-x: calc(var(--col-index)  - var(--center-x));
    --offset-y: calc(var(--row-index)  - var(--center-y));
    --distance-x: calc( (var(--offset-x) * var(--offset-x)) / var(--center-x));
    --distance-y: calc( (var(--offset-y) * var(--offset-y)) / var(--center-y));
  }
}

/*# sourceMappingURL=index.21dcbae6.css.map */
